<template>
    <div class="main-container">
        <Header />   
        <div class="secoes_site">     
            <div>
                <div class="container">
                    <div class="row">
                        <div class="col">
                            <div class="breadcrumb">
                                <h1>{{titulosecao}}</h1>
                                <b-breadcrumb class="page-breadcrumb" :items="items"></b-breadcrumb>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="blog-grid-area section-space--inner--50">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-12 col-md-12">
                        <div class="paginas-content" v-for="pagina in paginas" :key="pagina.id">
                            <!-- <h4 class="paginas-content__small-title">{{pagina.titulo}}</h4> -->
                            <p class="paginas-content__text" v-html="pagina.conteudo">{{pagina.conteudo}}</p>
                            <!-- <router-link to="/contact" class="ht-btn ht-btn--round">CONTACT US</router-link> -->
                        </div>
                    </div>                
                </div>
            </div>
        </div>       
        <Footer />
        <!-- <OffCanvasMobileMenu /> -->
        <!-- back to top start -->
        <back-to-top class="scroll-top" bottom="60px">
            <i class="ion-android-arrow-up"></i>
        </back-to-top>
        <!-- back to top end -->
    </div>
</template>

<script>

    import Header from '@/components/Header';
    import Footer from '../components/Footer'
   // import OffCanvasMobileMenu from '@/components/OffCanvasMobileMenu'

    import axios from 'axios'
    export default {
        components: {
            Header,
            Footer,
            //OffCanvasMobileMenu
        },
        created(){
            doSomething.call(this);
        },
        watch: {
        '$route' () {
            doSomething.call(this);
            }
        },
        data() {
            return {
                titulosecao: "",
                items: [
                    {
                        text: 'Home',
                        to: "/"
                    },
                    {
                        text: this.$route.params.pag,
                        active: true
                    }
                ],
                paginas: [],
               
            }
        },
        metaInfo: {
            title: 'Vix Inpeções',
            titleTemplate: `%s - empresa`,
            meta: [{
            name: 'description',
            content: 'Vix Inpeções'
            },
            {
            name: 'keywords',
            content: 'Vix Inspeções, inspenções, vistoria, vistorias de veículos escolares, veículos pesados, veículos de passeios'
            }
            ],
            htmlAttrs: {
                lang: 'pt-BR',
                amp: true
            }
        }
    }

    function doSomething() {
        var pagina = this.$route.params.pag;        
        axios.get(`https://sistema.gouarts.com/api/pagina/` + pagina + `?cliente=vitoriainspecoes`).then(res => {
            this.paginas = res.data;
            this.titulosecao = res.data.paginas.titulo;           
                      
        }).catch(err => {
            console.log(err);
        })
}
</script>
<style scoped>
.slider-area {
    height:250px;
}
</style>

